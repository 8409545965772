const arr = document.getElementsByClassName("faq__title");

function onclick(item) {
  const property = item.parentElement.childNodes[3].style.display;
  !property || property === "none"
    ? (item.parentElement.childNodes[3].style.display = "block")
    : (item.parentElement.childNodes[3].style.display = "none");
}

for (let k = 0; k < arr.length; k++)
  arr[k].addEventListener("click", () => onclick(arr[k]));
