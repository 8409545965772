import { isMobile } from "./helper";
import SiteHeader from "./siteHeader";
import { toggleMenu, addSticky, closeMenu } from "./navigation";
import FloatLabels from "./forms/float-labels";

window.addEventListener("load", () => {
  new SiteHeader();
  new FloatLabels();

  toggleMenu();

  if (isMobile()) {
    closeMenu();
    addSticky();
  }
});

window.onresize = () => {
  if (isMobile()) {
    closeMenu();
    addSticky();
  }
};

const applyButton = document.getElementById("applyButton");
const applyForm = document.getElementById("applyForm");

if (applyButton && applyForm) {
  applyForm.style.maxHeight = "0";
  applyForm.style.opacity = "0";
  applyForm.style.transition = "max-height 0.15s ease-out";
  applyButton.onclick = e => {
    e.preventDefault();
    if (applyForm.style.maxHeight === "500px") {
      applyForm.style.opacity = "0";
      applyForm.style.maxHeight = "0";
    } else {
      applyForm.style.opacity = "1";
      applyForm.style.maxHeight = "500px";
    }
  };
}
