const price = document.getElementById("price");
const priceInput = document.getElementById("priceInput");
const month = document.getElementById("month");
const monthInput = document.getElementById("monthInput");
const monthInstallment = document.getElementById("monthInstallment");
const rrsoInput = document.getElementById("rrsoInput");

const rrso = [
  {
    rrso: 0.1762,
    tax: 0.174667,
    step: 6
  },
  {
    rrso: 0.1293,
    tax: 0.172633,
    step: 6
  },
  {
    rrso: 0.1363,
    tax: 0.105965,
    step: 10
  },
  {
    rrso: 0.1381,
    tax: 0.089307,
    step: 12
  },
  {
    rrso: 0.1397,
    tax: 0.072648,
    step: 15
  },
  {
    rrso: 0.1405,
    tax: 0.061539,
    step: 18
  },
  {
    rrso: 0.1407,
    tax: 0.055984,
    step: 20
  },
  {
    rrso: 0.1409,
    tax: 0.047652,
    step: 24
  },
  {
    rrso: 0.1406,
    tax: 0.039323,
    step: 30
  },
  {
    rrso: 0.1398,
    tax: 0.033767,
    step: 36
  },
  {
    rrso: 0.139,
    tax: 0.029802,
    step: 42
  },
  {
    rrso: 0.1381,
    tax: 0.026826,
    step: 48
  },
  {
    rrso: 0.137,
    tax: 0.024511,
    step: 54
  },
  {
    rrso: 0.136,
    tax: 0.022661,
    step: 60
  }
];

if (monthInput) {
  for (let i = 1; i < rrso.length; i++) {
    const opt = document.createElement("option");
    opt.value = i.toString();
    opt.appendChild(document.createTextNode(rrso[i].step));
    monthInput.appendChild(opt);
  }
}

if (price) {
  month.max = rrso.length - 1;

  calc();
  price.onchange = function() {
    priceInput.value = valid(price.value, price.min, price.max);
    calc();
  };

  priceInput.onchange = function() {
    price.value = valid(priceInput.value, priceInput.min, priceInput.max);
    priceInput.value = valid(priceInput.value, priceInput.min, priceInput.max);
    calc();
  };

  month.onchange = function() {
    monthInput.value = month.value;
    calc();
  };

  monthInput.onchange = function() {
    month.value = valid(monthInput.value, monthInput.min, monthInput.max);
    monthInput.value = valid(monthInput.value, monthInput.min, monthInput.max);
    calc();
  };
}

function calc() {
  const valueToCalc = parseInt(priceInput.value, 10);
  const amount = valueToCalc * rrso[month.value].tax;
  rrsoInput.innerHTML = (rrso[month.value].rrso * 100).toFixed(2) + "%";
  monthInstallment.innerHTML = amount.toFixed(2).toString();
}

function valid(value, minValue, maxValue) {
  value = parseInt(value);
  minValue = parseInt(minValue);
  maxValue = parseInt(maxValue);
  if (value < minValue) {
    return minValue;
  } else if (value > maxValue) {
    return maxValue;
  } else {
    return value;
  }
}
