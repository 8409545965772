const popup = document.getElementById("popup");
const background = document.getElementById("background");
const button = document.getElementById("button");
const button2 = document.getElementById("button2");
const popupOrder = document.getElementById("popupOrder");
const backgroundOrder = document.getElementById("backgroundOrder");
const orderbutton = document.getElementById("orderbutton");
const order1 = document.getElementById("order1");
const tel = document.getElementById("tel");
const email = document.getElementById("email");
const acceptance = document.getElementById("acceptance");
const submitButton = document.getElementById("submitButton");
const clickButton= document.getElementsByClassName("clickButton");
for (let item of clickButton) {
  item.onclick = () => {
    visibleNormal();
  };
}

if (button) {
  button.onclick = () => {
    visibleNormal();
  };

  background.onclick = () => {
    visibleNormal();
  };
}

if (button2) {
  button2.onclick = () => {
    visibleNormal();
  };
}

if (order1) {
  order1.onclick = () => {
    visibleNormal();
  };

  background.onclick = () => {
    visibleNormal();
  };
}

if (orderbutton) {
  orderbutton.onclick = () => {
    visibleOrder();
  };

  backgroundOrder.onclick = () => {
    visibleOrder();
  };
}

function visibleOrder() {
  const childsOrder = popupOrder.childNodes;
  if (popupOrder.classList.contains("contact__invisible")) {
    popupOrder.classList.remove("contact__invisible");
    for (let i = 0; i <= childsOrder.length; i++) {
      if (isElement(childsOrder[i])) {
        childsOrder[i].classList.remove("contact__invisible");
      }
    }
  } else {
    popupOrder.classList.add("contact__invisible");
    for (let i = 0; i <= childsOrder.length; i++) {
      if (isElement(childsOrder[i])) {
        childsOrder[i].classList.add("contact__invisible");
      }
    }
  }
}

function visibleNormal() {
  const childs = popup.childNodes;
  if (popup.classList.contains("contact__invisible")) {
    popup.classList.remove("contact__invisible");
    for (let i = 0; i <= childs.length; i++) {
      if (isElement(childs[i])) {
        childs[i].classList.remove("contact__invisible");
      }
    }
  } else {
    popup.classList.add("contact__invisible");
    for (let i = 0; i <= childs.length; i++) {
      if (isElement(childs[i])) {
        childs[i].classList.add("contact__invisible");
      }
    }
  }
}

function isElement(o) {
  return typeof HTMLElement === "object"
    ? o instanceof HTMLElement
    : o &&
        typeof o === "object" &&
        true &&
        o.nodeType === 1 &&
        typeof o.nodeName === "string";
}

if (tel) {
  tel.onchange = () => {
    if (tel.value !== "") {
      email.placeholder = "Email";
    } else {
      email.placeholder = "Email*";
    }
    if (tel.value === "" && email.value === "") {
      tel.placeholder = "Numer telefonu*";
      email.placeholder = "Email*";
      submitButton.disabled = true;
    }
    if (tel.value !== "" && email.value === "") {
      submitButton.disabled = false;
    }
  };

  email.onchange = () => {
    if (email.value !== "") {
      tel.placeholder = "Numer telefonu";
    } else {
      tel.placeholder = "Numer telefonu*";
    }
    if (tel.value === "" && email.value === "") {
      tel.placeholder = "Numer telefonu*";
      email.placeholder = "Email*";
      submitButton.disabled = true;
    }
    if (tel.value === "" && email.value !== "") {
      submitButton.disabled = false;
    }
  };

  acceptance.onclick = () => {
    console.log("dziala");
    if (tel.value === "" && email.value === "") {
      setTimeout(() => {
        submitButton.disabled = true;
      }, 100);
    }
  };
}
